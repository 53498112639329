import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section__showcase py-5" }
const _hoisted_2 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PicfittedImage = _resolveComponent("PicfittedImage")!
  const _component_rich_text = _resolveComponent("rich-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.image)
        ? (_openBlock(), _createBlock(_component_PicfittedImage, {
            key: 0,
            src: _ctx.image?.url,
            width: "800"
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      (Array.isArray(_ctx.content.parts))
        ? (_openBlock(), _createBlock(_component_rich_text, {
            key: 1,
            parts: _ctx.content.parts,
            class: "px-0 px-md-5 text-md-center mt-4 mb-0"
          }, null, 8, ["parts"]))
        : _createCommentVNode("", true)
    ])
  ]))
}