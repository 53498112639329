
import { defineComponent } from 'vue'
import {ContentBlockBackground, ImageWithText} from '@/types/graphql.sdk'
import RichText from '@/components/blocks/core/RichText.vue';
import PicfittedImage from "@/components/PicfittedImage.vue";

export default defineComponent({
    components: {PicfittedImage, RichText },
    props: {
        bgColor:  Object as () => ContentBlockBackground,
        imageWithText: Object as () => ImageWithText,
    },
    setup(props) {
        return {
            image: props.imageWithText?.image,
            content: props.imageWithText?.content
        }
    }
});
